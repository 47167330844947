import React, {Fragment} from "react";
import BlogCard from "./BlogCard"
import Pagination from "./Pagination"

const BlogRoll = ({posts, numPages, currentPage, cardGrid="col-lg-4 col-md-6"}) => {
    return (
        <Fragment>
            <div className="blog-area pb-100">
                <div className="container">
                    <div className="row">
                        {posts &&
                        posts.map(({node: post}) => (
                            <BlogCard key={post.id} post={post} grid={cardGrid}/>
                        ))}

                        <Pagination currentPage={currentPage} numPages={numPages}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BlogRoll;
